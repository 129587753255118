<template>

<app-content :loading="is.loading && !is.initialised">

	<app-content-head title="Announcements" subtitle="Official news and reminders from the convention organisers.">

	</app-content-head>

	<app-content-body :loading="is.loading && is.initialised" :is-empty="!collection.length" placeholder="No notifications found.">

		<app-notification v-for="item in collection" :key="item.id" :item="item" :is-linked="item.meta.url" :as-notification="true" :last-read="lastRead" />

	</app-content-body>

</app-content>

</template>

<script>

import mixCollection from '@/mixin/collection'

export default {

	mixins: [mixCollection],

	data: function() {

		return {
			lastRead: false,
			pagination: false,
			endpoint: 'convention/announcements',
			responsive: {
				'convention/announcement/:id/update': this.onLiveUpdate
			},
			refresh: [
				'convention/announcement/add', 
				'convention/announcement/remove'
			]
		}

	},

	created: function() {

		this.lastRead = this.$store.getters['session/date/announcement']

	}

}

</script>

<style scoped>

</style>